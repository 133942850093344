<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      ref="edit_field"
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/memberface/EditFaceID"
      finish-router="kioskMemberFaceId"
      permission="Kiosk_FaceID_Edit"
      :submitAction="submitAction"
    >
      <template #facility="scope">
        <SlotFacility
          :row-data="scope.rowData" :company-id="scope.rowData.companyID"
          :facility-id="scope.rowData.facilityID" :is-edit="scope.isEdit" :disabled="scope.rootItem.edit_disable"
        />
      </template>

      <template #floor="scope">
        <SlotFloor :row-data="scope.rowData" :facility-id="scope.rowData.facilityID"
                   :disabled="scope.rootItem.edit_disable"
        />
      </template>

      <template #room="scope">
        <SlotRoom :row-data="scope.rowData" :floor-id="scope.rowData.floorID" :disabled="scope.rootItem.edit_disable"/>
      </template>

      <template #bed="scope">
        <SlotBed :row-data="scope.rowData" :room-id="scope.rowData.roomID" :disabled="scope.rootItem.edit_disable"/>
      </template>

      <template v-slot:photos="scope">
        <slot-photos
          ref="slot_photos"
          :root-item="scope.rootItem"
          :items="row_data['photos']"
          @updateValues="(value) => row_data['photos'] = value"
        />
      </template>
    </edit-field>
  </page-content>
</template>

<script>
import fields from './fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import PageContent from '@/components/PageContent'
import SlotFacility from '@/views/member/SlotFacility'
import SlotFloor from '@/views/member/SlotFloor'
import SlotRoom from '@/views/member/SlotRoom'
import SlotBed from '@/views/member/SlotBed'
import SlotPhotos from '@/components/FieldPhotos.vue'
import _ from 'lodash'

export default {
  name: 'Edit',
  components: {
    SlotPhotos,
    SlotBed,
    SlotRoom,
    SlotFloor,
    SlotFacility,
    PageContent,
    EditFieldHeader,
    EditField,
    fields,
  },
  data() {
    return {
      title: common.getMenuName('kioskMemberFaceId') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {
        photos: []
      }
    }
  },
  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.replace({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/memberface/GetFaceID?memberID=' + this.id)
      .then(res => {
        this.row_data = res.data.memberFace
      })
  },
  methods: {
    // beforeSubmit(callback) {
    //   const rowData = _.clone(this.row_data)
    //   if(this.row_data?.photos && this.row_data.photos[0]) {
    //     rowData.photo = this.row_data.photos[0] ?? null
    //     delete rowData.photos
    //   }
    //   callback(true, rowData)
    // },
    async submitAction(rowData, api, finishRouter) {
      // 构造含文件流的 formData
      const file = this.row_data.photos[0]
      const _rowData = _.clone(rowData)
      _rowData.photo = file
      delete _rowData.photos
      const formData = new FormData()
      for (const key in _rowData) {
        formData.append(key, _rowData[key])
      }

      // 提交
      await this.$requestWehealth({
        method: 'post',
        url: api,
        data: formData,
      })
      await this.$router.push({ name: finishRouter })
    },
  }
}
</script>

<style scoped>

</style>
